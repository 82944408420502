import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const TitleImage = ({ data }) => {
  return (
    <div className="box title-img gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="title-img__wrap">
          <div
            className="title-img__image box box--bottom-bordered long-box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <GatsbyImage
              image={data.Image23.childImageSharp.gatsbyImageData}
              alt="Image description"
              itemProp="contentUrl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleImage;
