import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageColumns = ({ data }) => {
  return (
    <section className="section section-columns image-columns box gs-animate">
      <div className="container container--bordered box box--top-bordered box--bottom-bordered">
        <div className="row">
          <div className="row__column image-column">
            <GatsbyImage
              image={data.Image24.childImageSharp.gatsbyImageData}
              alt="Image description"
              itemProp="contentUrl"
            />
          </div>
          <div className="row__column bottom-content-column">
            <div>
              <h3>Complex back end, simple front end</h3>
              <p>
                Kassatex has a multi-faceted business with ecommerce retail,
                brick and mortar stores, wholesale, and bundles, so we worked
                hard to make Shopify their business's home base.
              </p>
            </div>
          </div>
          <div className="row__column image-column wide-column">
            <GatsbyImage
              image={data.Image25.childImageSharp.gatsbyImageData}
              alt="Image description"
              itemProp="contentUrl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageColumns;
