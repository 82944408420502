import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const PairImages = ({ data }) => {
  return (
    <div className="container container--bordered box box--top-bordered">
      <div className="pair-images box box--splitted">
        <div className="box box--bottom-bordered pair-images__image">
          <GatsbyImage
            image={data.Image26.childImageSharp.gatsbyImageData}
            alt="Image description"
            itemProp="contentUrl"
          />
        </div>
        <div className="box box--bottom-bordered pair-images__image">
          <GatsbyImage
            image={data.Image27.childImageSharp.gatsbyImageData}
            alt="Image description"
            itemProp="contentUrl"
          />
        </div>
      </div>
    </div>
  );
};

export default PairImages;
