import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Headline from "../components/text-elements/headline";
import TitleImage from "../components/case-study-page/extra-modules/title-image";
import ImageColumns from "../components/case-study-page/extra-modules/image-columns";
import PairImages from "../components/case-study-page/extra-modules/pair-images";

const CaseStudyExtraPage = () => {
  const data = useStaticQuery(graphql`
    query {
      takeshape {
        getSetting {
          seoContent
        }
      }
      Image23: file(relativePath: { eq: "img23.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED)
        }
      }
      Image24: file(relativePath: { eq: "img24.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED)
        }
      }
      Image25: file(relativePath: { eq: "img25.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED)
        }
      }
      Image26: file(relativePath: { eq: "img26.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: FIXED)
        }
      }
      Image27: file(relativePath: { eq: "img27.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: FIXED)
        }
      }
    }
  `);

  return (
    <Layout headerClass="header--dark" breadcrumbClass="breadcrumb--active">
      <Helmet
        bodyAttributes={{
          class: "no-footer-border alt-header no-header-footer",
        }}
      />
      <Seo
        title="Case Study"
        description={data.takeshape.getSetting.seoContent}
      />
      <div className="main--wrap">
        <TitleImage data={data}></TitleImage>
        <ImageColumns data={data}></ImageColumns>
        <Headline
          customClass={"mobile-hide"}
          headline="Results"
          description="In just six weeks, the new site improved Kassatex’s conversion rate by 25%, earned them 7% more revenue per week, and kept customers on average three minutes longer."
        ></Headline>
        <PairImages data={data}></PairImages>
      </div>
    </Layout>
  );
};

export default CaseStudyExtraPage;
